import { Application } from "@hotwired/stimulus"

const application = Application.start()
import { Slideover, Tabs } from 'tailwindcss-stimulus-components';

// Configure Stimulus development experience
application.register('slideover', Slideover)
application.register('tabs', Tabs)

application.warnings = true
application.debug    = false
window.Stimulus      = application

export { application }

