import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "map", "parcels" ]

  connect() {
    this.markers = [];
    this.Lmap;
    this.initMap();
  }

  initMap(){
    var map = L.map(this.mapTarget).setView([46, 2], 6);
    var lat = this.latTarget;
    var lng = this.lngTarget;
    var marker;
    var latLngs = [];
    var bounds = new L.LatLngBounds();

    L.tileLayer(
      "https://data.geopf.fr/wmts?" +
      "&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0" +
      "&STYLE=normal" +
      "&TILEMATRIXSET=PM" +
      "&FORMAT=image/jpeg"+
      "&LAYER=ORTHOIMAGERY.ORTHOPHOTOS"+
      "&TILEMATRIX={z}" +
      "&TILEROW={y}" +
      "&TILECOL={x}",
      {
        minZoom : 6,
        maxZoom : 16,
        attribution : "IGN-F/Geoportail",
        tileSize : 256 // les tuiles du Géoportail font 256x256px
      }
    ).addTo(map) ;

    L.tileLayer(
      "https://data.geopf.fr/wmts?" +
      "&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0" +
      "&STYLE=normal" +
      "&TILEMATRIXSET=PM" +
      "&FORMAT=image/png"+
      "&LAYER=LANDUSE.AGRICULTURE.LATEST"+
      "&TILEMATRIX={z}" +
      "&TILEROW={y}" +
      "&TILECOL={x}",
      {
        opacity: 0.5,
        minZoom : 6,
        maxZoom : 16,
        attribution : "IGN-F/Geoportail",
        tileSize : 256 // les tuiles du Géoportail font 256x256px
      }
   ).addTo(map);

    var yellowIcon = new L.Icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-gold.png',
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });

    var markers = this.markers
    var parcels = this.parcelsTargets
    parcels.forEach(function(parcel){
      var lat = parcel.getAttribute('data-parcel-lat');
      var lng = parcel.getAttribute('data-parcel-lng');
      var marker = new L.marker([lat,lng], {icon: yellowIcon}).addTo(map);
      markers.push(marker)
      latLngs.push(new L.LatLng(lat,lng))
    });

    var latLngBounds = new L.LatLngBounds();
    latLngs.forEach(function (latLng) {
      latLngBounds.extend(latLng)
    })

    map.fitBounds(latLngBounds)
    this.Lmap = map
  }

  center(e){
    event.preventDefault();
    event.stopPropagation();
    var lat = e.target.closest('[data-parcel-id]').getAttribute('data-parcel-lat');
    var lng = e.target.closest('[data-parcel-id]').getAttribute('data-parcel-lng');
    this.Lmap.setView([lat,lng],15);
  }
}
