import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'searchbar', 'searchinput' ]

  toggle (event) {
    event.preventDefault()
    if ( this.searchbarTarget.classList.contains('hidden') ) {
      this.searchbarTarget.classList.remove('hidden')
      this.searchinputTarget.focus()
    } else {
      this.searchbarTarget.classList.add('hidden')
    }
  }
}
