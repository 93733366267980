import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "table", "header", "headercopy" ]

  onTableScroll(event) {
    this.headerTarget.scrollTo({
      left: this.tableTarget.scrollLeft,
      top: false,
    })
    this.headercopyTarget.scrollTo({
      left: this.tableTarget.scrollLeft,
      top: false,
    })
  }

  onHeadercopyScroll(event) {
    this.tableTarget.scrollTo({
      left: this.headercopyTarget.scrollLeft,
      top: false,
    })
  }

  stickTableHeader() {
    var tableDimensions = this.tableTarget.getBoundingClientRect()
    // check if inside the visible tab panel
    if (tableDimensions.width > 0) {
      if (tableDimensions.top < 0 && tableDimensions.bottom >= 100) {
        this.headercopyTarget.classList.remove("hidden")
        this.headercopyTarget.scrollTo({
          left: this.tableTarget.scrollLeft,
          top: false,
        })
      } else  {
        // Reset Style
        this.headercopyTarget.classList.add("hidden")
      }
    }
  }
}
