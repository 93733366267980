import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "table", 'buttonRight', 'buttonLeft' ]

  connect() {
    this.originalDimensions = this.tableTarget.getBoundingClientRect()
  }
  navScrollLeft() {
    this.tableTarget.scrollTo({
      left: this.tableTarget.scrollLeft += -180,
      behavior: 'smooth'
    })
    this.buttonRightTarget.firstElementChild.classList.remove('fill-grey-default')
    this.buttonRightTarget.firstElementChild.classList.add('fill-secondary')
    this.buttonRightTarget.classList.remove('cursor-pointer')
    this.buttonRightTarget.classList.add('cursor-pointer')
    if (this.tableTarget.scrollLeft == 0) {
        this.buttonLeftTarget.firstElementChild.classList.remove('fill-secondary')
        this.buttonLeftTarget.firstElementChild.classList.add('fill-grey-default')
        this.buttonLeftTarget.classList.remove('cursor-pointer')
    }
  }
  navScrollRight() {
    this.tableTarget.scrollTo({
      left: this.tableTarget.scrollLeft += 180,
      behavior: 'smooth'
    })
    this.buttonLeftTarget.firstElementChild.classList.remove('fill-grey-default')
    this.buttonLeftTarget.firstElementChild.classList.add('fill-secondary')
    this.buttonLeftTarget.classList.remove('cursor-pointer')
    this.buttonLeftTarget.classList.add('cursor-pointer')
    var newScrollLeft = this.tableTarget.scrollLeft,
        width=this.tableTarget.offsetWidth,
        scrollWidth=this.tableTarget.scrollWidth;
    if (scrollWidth-newScrollLeft==width) {
        this.buttonRightTarget.firstElementChild.classList.add('fill-grey-default')
        this.buttonRightTarget.classList.remove('cursor-pointer')
    }
  }
}
