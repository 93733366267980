import { Controller } from "@hotwired/stimulus"
let debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = [ "autocomplete", "input" ]

  suggest() {
    var url = this.data.get("suggest-path")
    var ul = this.autocompleteTarget

    // Call ajax when 3 characters present in input
    if (this.inputTarget.value.length >= 3) {
      var query = 'suggestion=' + this.inputTarget.value
      Rails.ajax({
        url: url,
        type: 'post',
        data: query,
        success: function(data) {
          ul.innerHTML = "";
          if (data['response'].length == 0) {
            var li = document.createElement('li')
            li.classList.add('px-4', 'py-2')
            li.innerHTML = "<i>Aucune localité ne correspond à cette recherche.</i>"
            ul.appendChild(li)
            ul.classList.remove('hidden')
          } else {
            data['response'].forEach(function(city) {
              var li = document.createElement('li')
              var a = document.createElement('a')
              a.classList.add('block', 'px-4', 'py-2', 'hover:bg-secondary')
              a.href = city['url']
              a.innerHTML = city['label']
              li.appendChild(a)
              ul.appendChild(li)
              ul.classList.remove('hidden')
            });
          }
        },
        error: function(data) {console.log(data)}
      })
    }
    else {
      ul.innerHTML = ""
      ul.classList.add('hidden')
    }
  }

  close() {
    var ul = this.autocompleteTarget
    ul.innerHTML = ""
    ul.classList.add('hidden')
  }

  initialize() {
    this.suggest = debounce(this.suggest, 500).bind(this)
  }
}
