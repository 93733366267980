// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'controllers'
import * as siret from "siret"

Rails.start()
ActiveStorage.start()
import "../css/application.css"
import "../css/nav.css"
import "../css/ads.css"

// LEAFLET

import L from 'leaflet';
import * as Gp from 'geoportal-extensions-leaflet';
window.L = L
window.Gp = Gp
window.siret = siret
window.Rails = Rails

import "leaflet/dist/leaflet.css"
// https://github.com/PaulLeCam/react-leaflet/issues/255
import marker from 'leaflet/dist/images/marker-icon.png';
import marker2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: marker2x,
    iconUrl: marker,
    shadowUrl: markerShadow
});

// Roboto Font
// @see https://fontsource.org/docs/getting-started
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/700.css";
