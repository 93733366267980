import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "map", "lat", "lng" ]

  connect() {
    var map = L.map(this.mapTarget).setView([46, 2], 6);
    var lat = this.latTarget;
    var lng = this.lngTarget;
    var marker;

    L.tileLayer(
      "https://data.geopf.fr/wmts?" +
      "&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0" +
      "&STYLE=normal" +
      "&TILEMATRIXSET=PM" +
      "&FORMAT=image/jpeg"+
      "&LAYER=ORTHOIMAGERY.ORTHOPHOTOS"+
      "&TILEMATRIX={z}" +
      "&TILEROW={y}" +
      "&TILECOL={x}",
      {
        minZoom : 6,
        maxZoom : 16,
        attribution : "IGN-F/Geoportail",
        tileSize : 256 // les tuiles du Géoportail font 256x256px
      }
    ).addTo(map);


    L.tileLayer(
      "https://data.geopf.fr/wmts?" +
      "&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0" +
      "&STYLE=normal" +
      "&TILEMATRIXSET=PM" +
      "&FORMAT=image/png"+
      "&LAYER=LANDUSE.AGRICULTURE.LATEST"+
      "&TILEMATRIX={z}" +
      "&TILEROW={y}" +
      "&TILECOL={x}",
      {
        opacity: 0.5,
        minZoom : 6,
        maxZoom : 16,
        attribution : "IGN-F/Geoportail",
        tileSize : 256 // les tuiles du Géoportail font 256x256px
      }
   ).addTo(map);

    Gp.Services.getConfig({
        apiKey: 'calcul',
        onSuccess: function (response) {
            var searchCtrl = L.geoportalControl.SearchEngine({
              position: 'topright',
              zoomTo: 14,
              displayMarker: true,
              collapsed: true,
            });
            map.addControl(searchCtrl);
        }
    });

    var yellowIcon = new L.Icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-gold.png',
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });

    map.on('click', function(e){
        if (marker != undefined) {
          map.removeLayer(marker);
        }
        marker = new L.marker(e.latlng, {icon: yellowIcon}).addTo(map);
        lat.value = e.latlng.lat
        lng.value = e.latlng.lng
    });
    this.Lmap = map
  }

  locate(){
    this.Lmap.locate({
      setView: true,
      watch: true,
      minZoom: 6,
      maxZoom: 13
    });
  }

}
