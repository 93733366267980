import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "button" ]

  connect() {
    var is_siret = siret.isSIRET(this.inputTarget.value)
    this.buttonTarget.disabled = !is_siret
  }

  siret(e) {
    var is_siret = siret.isSIRET(e.target.value)
    if(is_siret){
      this.inputTarget.style.border= "2px solid green";
      this.buttonTarget.disabled = !is_siret
    }
    else {
      this.inputTarget.style.border= "2px solid red";
      this.buttonTarget.disabled = !is_siret
    }
  }

}
