import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  close(){
    document.getElementsByClassName('flash-messages-container')[0].innerHTML = "";
  }

  closeFlash(){
    event.target.remove()
  }

}
