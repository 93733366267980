import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "map"]
  static values = {
    defaultLat: Number,
    defaultLng: Number
  }

  connect() {
    var lat = this.data.get("default-lat");
    var lng = this.data.get("default-lng");
    var map = L.map(this.mapTarget).setView([lat, lng], 12);

    L.tileLayer(
      "https://data.geopf.fr/wmts?" +
      "&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0" +
      "&STYLE=normal" +
      "&TILEMATRIXSET=PM" +
      "&FORMAT=image/jpeg"+
      "&LAYER=ORTHOIMAGERY.ORTHOPHOTOS"+
      "&TILEMATRIX={z}" +
      "&TILEROW={y}" +
      "&TILECOL={x}",
      {
        minZoom : 6,
        maxZoom : 16,
        attribution : "IGN-F/Geoportail",
        tileSize : 256 // les tuiles du Géoportail font 256x256px
      }
    ).addTo(map) ;

    L.tileLayer(
      "https://data.geopf.fr/wmts?" +
      "&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0" +
      "&STYLE=normal" +
      "&TILEMATRIXSET=PM" +
      "&FORMAT=image/png"+
      "&LAYER=LANDUSE.AGRICULTURE.LATEST"+
      "&TILEMATRIX={z}" +
      "&TILEROW={y}" +
      "&TILECOL={x}",
      {
        opacity: 0.5,
        minZoom : 6,
        maxZoom : 16,
        attribution : "IGN-F/Geoportail",
        tileSize : 256 // les tuiles du Géoportail font 256x256px
      }
   ).addTo(map);

  }

  locate() {
    map.locate({
      setView: true,
      watch: true,
      minZoom: 6,
      maxZoom: 16
    });
  }

}
