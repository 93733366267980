import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  change(e){
    event.preventDefault();
    event.stopPropagation();
    var id = e.target.closest('[data-parcel-id]').getAttribute('data-parcel-id');
    var link = "update_" + id
    document.getElementsByClassName(link)[0].click()
  }

}
