import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "submit"]

  check_rain(){
    var input = event.target.parentNode.getElementsByClassName("js-input-alert")[0];
    if(event.target.checked){
      input.disabled = false;
      input.focus();
      input.value = 5;
      input.style.border= "1px solid #e2e8f0";
      this.submitTarget.disabled = false;
    }
    else{
      input.disabled=true;
      input.value = null;
      input.value = ''
      input.style.border= "1px solid #e2e8f0";
      this.submitTarget.disabled = false;
    }
  }

  check_wind(){
    var input = event.target.parentNode.getElementsByClassName("js-input-alert")[0];
    if(event.target.checked){
      input.disabled = false;
      input.focus();
      input.value = 20;
      input.style.border= "1px solid #e2e8f0";
      this.submitTarget.disabled = false;
    }
    else{
      input.disabled=true;
      input.value = null;
      input.value = ''
      input.style.border= "1px solid #e2e8f0";
      this.submitTarget.disabled = false;
    }
  }

  check_empty(){
    if(event.target.value.length == 0) {
      event.target.style.border= "2px solid red";
      this.submitTarget.disabled = true;
    }
    else {
      event.target.style.border= "1px solid #e2e8f0";
      this.submitTarget.disabled = false;
    }
  }


}
